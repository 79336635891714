<template>
<div>
  <h1>Statistics</h1>
  <h2>Logins</h2>
  <LoginChart v-if="!isLoading" :statistics="login"/>
  <h2>Unique Logins</h2>
  <LoginChart v-if="!isLoading" :statistics="uniqueLogin"/>
  <h2>Views</h2>
  <LoginChart v-if="!isLoading" :statistics="views"/>
</div>
</template>
<script>
import LoginChart from "@/components/admin/users/LoginChart.vue";
export default {
  components: {
    LoginChart
  },
  data() {
    return {
      isLoading: false
    };
  },
  async created() {
    this.isLoading = true
    await this.fetchLogin();
    await this.fetchUniqueLogin();
    await this.fetchViews();
    this.isLoading = false
  },
  computed: {
    login() {
      return this.$store.state.Statistics.login;
    },
    uniqueLogin() {
      return this.$store.state.Statistics.uniqueLogin;
    },
    views() {
      return this.$store.state.Statistics.views;
    },
  },
  methods: {
    async fetchLogin() {
      await this.$store.dispatch("Statistics/fetchLogin");
    },
    async fetchUniqueLogin() {
      await this.$store.dispatch("Statistics/fetchUniqueLogin");
    },
    async fetchViews() {
      await this.$store.dispatch("Statistics/fetchViews");
    },
  },
};
</script>